* {
    --header_bg: #edecd2;
    --header_text: #385c5e;
    --lime_green: #32b049;
    --lime_green_hover: #49e966;
    --lime_green_active: #2b8f3d;
    --light_green: #d4ffdb;
    --input_focus: #dbf0de;
    --light_grey: #f7f7f7;
    --grey_text: #828d91;
    --gold: #d1ab35;
    --separator: #0002;
    --blue: #7DB1F2;
    --blue_active: #c1dafa;
    --blue_hover: #3e72bb;
    --dark_text: #34393D;
}

* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}

.header_bg {
    background: #edecd2;
}

.header_text {
    color: #385c5e;
}

.lime_green {
    background: #32b049;
}
.text_dark {
    color: var(--dark_text);
}

.light_green {
    background: #b9e4c0;
}

.light_grey {
    background: #f7f7f7;
}

.grey_text {
    color: #828d91;
}

.gold {
    background: #d1ab35;
}

.separator_color {
    background: #0002;
}

.full_width {
    width: 100%;
}

.mb_0{
    margin-bottom: 0px;
}
.pb_0{
    padding-bottom: 0px;
}

._100_width{
    width: 100%;
}
._90_width{
    width: 90%;
}
._80_width{
    width: 80%;
}
._70_width{
    width: 70%;
}
._60_width{
    width: 60%;
}
._50_width{
    width: 50%;
}
._40_width{
    width: 40%;
}
._30_width{
    width: 30%;
}
._20_width{
    width: 20%;
}
._10_width{
    width: 10%;
}

.flex_row {
    display: flex;
    flex-direction: row;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.flex_row_reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex_column_reverse {
    display: flex;
    flex-direction: column-reverse;
}

.justify_center {
    display: flex;
    justify-content: center;
}

.justify_start {
    display: flex;
    justify-content: flex-start;
}

.justify_end {
    display: flex;
    justify-content: flex-end;
}

.justify_between {
    display: flex;
    justify-content: space-between;
}

.align_center {
    display: flex;
    align-items: center;
}

.align_start {
    display: flex;
    align-items: flex-start;
}
.gravity_center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.align_end {
    display: flex;
    align-items: flex-end;
}

.align_between {
    display: flex;
    align-items: space-between;
}

.margin_each_x5 * {
    margin: 0px 5px;
}

.margin_each_x10 * {
    margin: 0px 10px;
}

.margin_each_y5 * {
    margin: 5px 0px;
}

.margin_each_y10 * {
    margin: 10px 0px;
}

.margin_auto{
    margin: auto;
}

.flex_1 {
    flex: 1;
}

.flex_2 {
    flex: 2;
}

.flex_3 {
    flex: 3;
}

.flex_4 {
    flex: 4;
}

.flex_5 {
    flex: 5;
}

.flex_6 {
    flex: 6;
}

.flex_7 {
    flex: 7;
}

.flex_8 {
    flex: 8;
}

.flex_9 {
    flex: 9;
}

.flex_10 {
    flex: 10;
}

.mt-20 {
    margin-top: 20px;
}

.display_none {
    display: none;
}

.p_10{
    padding: 10px;
}
.p_10{
    padding: 10px;
}
.p_20{
    padding: 20px;
}
.p_30{
    padding: 30px;
}
.p_40{
    padding: 40px;
}
.p_50{
    padding: 50px;
}
.p_60{
    padding: 60px;
}
.p_70{
    padding: 70px;
}
.p_80{
    padding: 80px;
}
.p_90{
    padding: 90px;
}
.p_100{
    padding: 100px;
}



@media screen and (max-width: 911px) {
    .sm_display_none {
        display: none;
    }
    .sm_display_block {
        display: block;
    }
}