@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
    font-family: 'Roboto', sans-serif;
    background-color: white; 
}

.logos {padding-top: 10px;}

.steps_container {
    width: 100%;
    display: flex;
    color: #585F63;
    justify-content: space-between;
    margin-bottom: 35px;
}

.step_progress {
    background-color: #CDCDCD;
    width: 98%;
    height: 8px;
}

.text-blue{
    color: var(--blue);
}

.steps_container .step_count {
    border-radius: 50%;
    color: #585F63;
    line-height: 1.5rem;
    border: 0.05rem solid #585F63;
    margin-right: 10px;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
    text-align: center;
    position: relative;
    -webkit-flex-shrink: 0;
}

.step_progress.active {
    background-color: var(--blue);
}

.text_center {text-align: center; margin: 40px 0 40px 0;}

.separator_line {
    border-bottom: 1px solid var(--separator);
}

.header_section {
    margin: 0 auto 24px;
    width: 766px;
    max-width: 1160px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 0.75em;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--header_text);
    background-color: var(--header_bg);
    padding: 1em;
    flex: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -webkit-flex: 0;
}

.header_content,
.form_section .form_content {
    padding: 32px 99px 16px 99px;
}



.radio_button{
    position: relative;
}

.radio_button input[type="radio"],.radio_button input[type="checkbox"]{
    position: absolute;
    visibility: hidden;
}

.heading_1 {
    font-size: 1.875rem;
    line-height: 1.23;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.para_1 {
    font-size: 1.375rem;
    line-height: 1.23;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin-bottom: 1em;
}

.para_2 {
    line-height: 1.23;
    letter-spacing: 0.5px;
    font-size: 1em;
    display: flex;
    align-items: center;
}

.form_section {
    margin: 0 auto 24px;
    width: 766px;
    min-height: 480px;
    max-width: 1160px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 24px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
}

.para_3 * {
    margin: 5px;
}

.para_3 {
    color: var(--grey_text);
    display: flex;
    align-items: center;
}

.para_4 {
    font-size: 0.89rem;
    line-height: 1.36;
    color: #AAAAAA;
    margin-top: 24px;
    margin-bottom: 0;
    size: 14px;
    font-weight: 300;
}

a {
    color: var(--blue_hover);
    text-decoration: none;
}

.para_5 img {
    margin-right: 10px;
}

.para_5 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 400;
    color: #585F63;
    background-color: #F7F7F7;
    width: 100%;
    padding: 24px;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
}

.form_field {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.form_field .required{
    display: none;
}

.form_field.is-error .required{
    display: block;
} 

.form_field label {
    font-size: 1em;
    line-height: 1;
    font-weight: 400;
    color: #34393D;
    display: block;
    text-align: start;
    margin-bottom: 0.4em;
}

.form_field input[type="text"],
.form_field input[type="email"],
.form_field input[type="password"],
.form_field select {
    display: block;
    width: 100%;
    background: rgb(247, 247, 247);
    line-height: 1.4;
    padding: 0.4em 0.5em;
    border-radius: 0px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(235, 235, 235) rgb(235, 235, 235);
    border-image: initial;
    appearance: none;
    font-size: 22px;
    text-align: left;
}

.form_field.is-error input[type="text"], 
.form_field.is-error input[type="password"], 
.form_field.is-error select{
    border-color: rgb(235, 235, 235) rgb(235, 235, 235) red;
}

.form_field .required {
    font-size: 0.875rem;
    line-height: 1.2;
    font-weight: 400;
    color: #E41518;
    display: block;
    padding-top: 2px;
    padding-left: 0;
    position: relative;
}

.form_input_group {
    display: flex;
    justify-content: space-between;
}

.form_input_group .form_field:not(:first-child) {
    margin-left: 10px;
}

::-webkit-input-placeholder {
    opacity: 0.5;
}

:-ms-input-placeholder {
    opacity: 0.5;
}

::placeholder {
    opacity: 0.5;
}

.btn,.btn_submit {
    height: 50px;
    width: 272px;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer !important;
    outline: none;
    padding: 0.83rem 1.9rem;
    vertical-align: middle;
    opacity: 1;
    font-size: 1.1487rem;
    line-height: 1.5rem;
    letter-spacing: 0.04rem;
    transition: all 0.15s;
    -moz-transition: all 0.15s;
    text-decoration: none;
    text-align: center;
}

.btn_submit {
    background-color: var(--blue);
}

.btn:hover,.btn:focus {
    background-color: var(--blue_hover) !important;
}

.btn:active {
    background: var(--blue_active) !important;
}
.btn.green{
    background-color: var(--light_gray);
    color: #ccc;
    border: 1px solid #ccc;
}
.btn.blue{
    background-color: var(--blue);
}

.btn.green:hover{
    filter: brightness(90%);
}

.btn_submit:disabled {
    background-color:darkgrey;
    cursor: default;
}

.btn.green:hover, .btn.green:focus {
    background-color: var(--blue_active) !important;
    cursor: default;
    color: var(--dark_text);
    border: 1px solid transparent;
}

.btn-sm {
    height: 30px;
    width: 100px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
    padding: .55rem .9rem;
    vertical-align: middle;
    opacity: 1;
    font-size: .7rem;
    line-height: .7rem;
    letter-spacing: 0.04rem;
    transition: all 0.15s;
    -webkit-transition: all 0.15s;
    -moz-transition: all 0.15s;
    text-decoration: none;
    text-align: center;
}

textarea:focus,
input:focus {
    outline-color: rgba(50, 176, 73, 0.15);
    outline-style: solid;
    outline-width: 3px;
    outline-offset: 1px;
}


@media screen and (max-width: 911px) {
    body {
        padding: 0px 20px;
    }
    .header_section,
    .form_section {
        width: 100%;
    }
    .form_section .form_content {
        padding: 5px;
    }
    .header_content {
        padding: 5px;
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .header_content p {
        text-align: center;
    }
    .heading_1 {
        font-size: 1.4em;
    }
    .para_1{
        font-size: 1.1em;
    }
    .para_2 {
        font-size: 1em;
    }
    .para_3,
    .para_4 {
        font-size: 0.85em;
    }
    .para_5 {
        font-size: 1em;
    }
    .form_input_group {
        display: flex;
        flex-direction: column;
    }
    .form_input_group .form_field {
        margin-left: 0px !important;
        flex: 1;
    }
    .form_section {
        border: none;
    }
    .steps_container {
        margin-top: 20px;
        margin-bottom: 15px;
    }
}

/* Popup style */
.popup-box {
    position: fixed;
    display: flex;
    width: 100%;
    max-width: 500px;
    padding: 36px 0px 45px;
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 3px;
    border: 1px solid rgba(205, 205, 205, 0.5);
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    transform: translate(-50%, -50%);
    }
  
.btn_filled{
    border: 1px solid rgb(50, 176, 73);
    border-radius: 5px;
    background-color: rgb(50, 176, 73);
    color: rgb(255, 255, 255);
    cursor: pointer;
    outline: none;
    padding: 0px;
    vertical-align: middle;
    opacity: 1;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: normal;
    box-sizing: border-box;
    transition: background-color 0.15s ease 0s;
    width: 150px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    margin: auto
}

.btn_outline{
    border: 1px solid rgb(50, 176, 73);
    border-radius: 5px;
    background-color: white;
    color: rgb(50, 176, 73);
    cursor: pointer;
    outline: none;
    padding: 0px;
    vertical-align: middle;
    opacity: 1;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: normal;
    box-sizing: border-box;
    transition: background-color 0.15s ease 0s;
    width: 150px;
    height: 40px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    margin: auto
}

.popup-box p{
    text-align: center;
    align-self: stretch;
    padding: 0 89px;
    margin-bottom: 3em;
    line-height: 1.63;
    font-size: 16px;
    -webkit-align-self: stretch;
}

.popup-box h2{
    font-size: 30px;
    line-height: 1.69;
    color: #585F63;
    font-weight: normal;
    text-align: center;
    padding: 0 89px;
    margin-bottom: 1em;
} 

.close-icon {
content: 'x';
cursor: pointer;
position: fixed;
right: 0px;
top: 0px;
background: #ededed;
width: 25px;
height: 25px;
line-height: 20px;
text-align: center;
font-size: 20px;
}